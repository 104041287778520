import React from "react";
import { Helmet } from "react-helmet"

import PageWrapper from "../components/PageWrapper";
import { Box } from "../components/Core";
import Contact from "../sections/others/Contact";

const ContactPage = () => {
  return (
    <>
      <Helmet>
         <meta charSet="utf-8" />
         <title>BigDog.zone - Contact</title>
         <link rel="canonical" href={"https://bigdog.zone/contact"} />
      </Helmet>
      <PageWrapper darkTheme>
        <Box pt={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  );
};

export default ContactPage;
